import Breadcrumbs from '@/components/Breadcrumbs';
import Layout from '@/components/Layout';
import PlantWater from '@/components/PlantWater';
import SEO from '@/components/SEO';
import TreeWaterAndSun from '@/components/TreeWaterAndSun';
import { Plant } from '@/lib/connectors/plants';
import { usePlants } from '@/lib/data/plants';
import { TreeGuild, useTreeGuild } from '@/lib/data/treeGuild';
import { useTree } from '@/lib/data/trees';
import clsx from 'clsx';
import { PageProps } from 'gatsby';
import * as React from 'react';
import { FC } from 'react';
import { useSearchParam } from 'react-use';

const TreeGuildCompanionPlantsPage: FC<PageProps> = () => {
  const guildId = useSearchParam('guildId');
  const treeGuildData = useTreeGuild(guildId);
  const guild = treeGuildData.guild as TreeGuild;
  const tree = useTree(treeGuildData.guild?.tree);
  const plants = usePlants(tree);
  if (!guild) return null;

  const selectedPlants = [
    ...plants.recommendedPlants,
    ...plants.otherPlants,
  ].filter((p) => guild?.plants.includes(p.name));

  const insideDripLine = selectedPlants.filter((p) => p.dripLine.includes('1'));
  const onDripLine = selectedPlants.filter((p) => p.dripLine.includes('2'));
  const outsideDripLine = selectedPlants.filter((p) =>
    p.dripLine.includes('3')
  );
  const unknown = selectedPlants.filter((p) => !p.dripLine.length);

  function renderWarnings() {
    function warning(role: string) {
      return (
        <div className="text-gray-700 mt-4">
          ❌ No plant has the {role} role.
        </div>
      );
    }

    const hasAttractor = selectedPlants.some((p) => p.attractor);
    const hasRepeller = selectedPlants.some((p) => p.repeller);
    const hasSuppressor = selectedPlants.some((p) => p.supressor);
    const hasMulcher = selectedPlants.some((p) => p.mulcher);
    const hasFixer = selectedPlants.some((p) => p.fixer);
    const hasAccumulator = selectedPlants.some((p) => p.accumulator);
    const hasGroundCover = selectedPlants.some((p) => p.groundCover);

    return (
      <>
        {!hasAttractor && warning('attractor')}
        {!hasRepeller && warning('repeller')}
        {!hasSuppressor && warning('suppressor')}
        {!hasMulcher && warning('mulcher')}
        {!hasFixer && warning('fixer')}
        {!hasAccumulator && warning('accumulator')}
        {!hasGroundCover && warning('ground cover')}
        {hasAttractor &&
          hasRepeller &&
          hasSuppressor &&
          hasMulcher &&
          hasFixer &&
          hasAccumulator &&
          hasGroundCover && (
            <div className="text-gray-700 mt-4">
              ✔ All roles are covered by at least a plant.
            </div>
          )}
      </>
    );
  }

  function renderPlants(plantsToRender: Plant[]) {
    if (!plantsToRender.length) return <div>No plants.</div>;

    return plantsToRender.map((p) => (
      <div key={p.name}>
        <h3 className="text-lg font-bold mt-4 mb-2">
          {p.name} <PlantWater plant={p} className="font-medium" />
        </h3>
        {p.properties && <div className="ml-4">{p.properties}</div>}
      </div>
    ));
  }

  const title = `${guild?.tree} Guild Result`;
  return (
    <Layout className="relative">
      <SEO url="/tree-guild-result/" title={title} description={title} />

      <div className="container m-auto">
        <h1 className="text-3xl text-center font-bold w-full mt-10 mb-4">
          {title}
        </h1>
        <Breadcrumbs
          links={[
            { src: '/tree-guilds/', text: 'My Tree Guilds' },
            {
              src: `/tree-guild/?guildId=${guild.id}`,
              text: `${guild.tree} Guild`,
            },
            {
              src: `/tree-guild-companion-plants/?guildId=${guild.id}`,
              text: `Companion Plants`,
            },
          ]}
          currentPage="Result"
        />

        <div className="flex w-full justify-center -mx-2 -my-2 mt-10">
          <div
            className={clsx(
              'shadow-lg rounded-md p-8 m-2 flex flex-col items-center',
              'bg-secondary text-white relative'
            )}
          >
            <div className="text-center text-7xl mb-4">🌳</div>
            <div className="text-center text-xl">
              {guild.variety ? ` ${guild.variety}` : ''}
            </div>
            <div className="absolute top-1 right-1 text-black">
              <TreeWaterAndSun tree={guild.tree} />
            </div>
          </div>

          {guild.soulmate && (
            <div
              className={clsx(
                'shadow-lg rounded-md p-8 m-2 flex flex-col items-center',
                'bg-secondary text-white relative'
              )}
            >
              <div className="text-center text-7xl mb-4">🌳</div>
              <div className="text-center text-xl">{guild.soulmate}</div>
              <div className="absolute top-1 right-1 text-black">
                <TreeWaterAndSun tree={guild.tree} />
              </div>
            </div>
          )}
        </div>

        {tree.warning
          ?.split('*')
          .map((w) => w.trim())
          .filter(Boolean)
          .map((warning) => (
            <div key={warning} className="mt-4 text-gray-700">
              ⚠ {warning}
            </div>
          ))}

        <h2 className="text-xl font-bold mt-10 mb-2">
          Companion Plants Inside Dripline
        </h2>
        <div className="pl-4">{renderPlants(insideDripLine)}</div>

        <h2 className="text-xl font-bold mt-10 mb-2">
          Companion Plants On Dripline
        </h2>
        <div className="pl-4">{renderPlants(onDripLine)}</div>

        <h2 className="text-xl font-bold mt-10 mb-2">
          Companion Plants Outside Dripline
        </h2>
        <div className="pl-4">{renderPlants(outsideDripLine)}</div>

        {!!unknown.length && (
          <>
            {' '}
            <h2 className="text-xl font-bold mt-10 mb-2">
              Other Companion Plants
            </h2>
            <div className="pl-4">{renderPlants(unknown)}</div>
          </>
        )}

        {renderWarnings()}

        <div className="mb-10" />
      </div>
    </Layout>
  );
};

export default TreeGuildCompanionPlantsPage;
