import { useTree } from '@/lib/data/trees';
import * as React from 'react';
import { FC } from 'react';

type TreeWaterAndSunProps = {
  tree: string;
};

const TreeWaterAndSun: FC<TreeWaterAndSunProps> = ({ tree }) => {
  const completeTree = useTree(tree);

  function renderWithTooltip(label: string, tooltip: string) {
    return (
      <span className="has-tooltip">
        {label}
        <span className="tooltip rounded shadow-lg p-1 bg-white bottom-6 -left-1/2 whitespace-nowrap cursor-default">
          {tooltip}
        </span>
      </span>
    );
  }

  return (
    <div className="flex gap-0.5">
      {completeTree.soilHumidity.includes('D') &&
        renderWithTooltip('🌵', 'Well Drained')}
      {completeTree.soilHumidity.includes('P') &&
        renderWithTooltip('💧', 'Puddling')}
      {completeTree.sun.includes('+') && renderWithTooltip('🌕', 'Full Sun')}
      {completeTree.sun.includes('/') &&
        renderWithTooltip('🌓', 'Partial Shade')}
      {completeTree.sun.includes('-') && renderWithTooltip('🌑', 'Shade')}
    </div>
  );
};

export default TreeWaterAndSun;
